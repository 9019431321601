import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  NgZone,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  GoogleMap,
  MapCircle as MapCircleComponent,
  MapInfoWindow,
  MapMarker as MapMarkerComponent,
} from '@angular/google-maps';
import { Observable } from 'rxjs';

import { MapsService } from '../maps.service';

interface LatLngLiteral {
  lat: number;
  lng: number;
}

export interface MapMarkerOption extends LatLngLiteral {
  id: string;
  icon?: string;
  info?: string;
  radius?: number;
}

export interface MapCircleOption {
  id: string;
  center: LatLngLiteral;
  radius: number;
}

export interface MapAutoCompleteChangeEvent {
  name: string;
  formattedAddress: string;
  latitude: number;
  longitude: number;
}

const defaultZoom = 8;
const defaultCenter: LatLngLiteral = { lat: 52.0458902, lng: 4.4896977 };
const defaultOption: google.maps.MapOptions = {
  streetViewControl: false,
  mapTypeControl: false,
  fullscreenControl: false,
};
const defaultCircleOptions: google.maps.CircleOptions = {
  draggable: false,
  editable: true,
};
const defaultMarkerOptions: google.maps.MarkerOptions = {
  draggable: false,
};

@Component({
  selector: 'sb-maps',
  templateUrl: './maps.component.html',
})
export class MapsComponent implements OnChanges {
  private readonly defaultZoom = defaultZoom;
  options = defaultOption;
  markerOptions = defaultMarkerOptions;
  circleOptions = defaultCircleOptions;
  center = defaultCenter;

  @ViewChild(GoogleMap, { static: false })
  map?: GoogleMap;

  @ViewChild(MapInfoWindow, { static: false })
  infoWindowComponent?: MapInfoWindow;

  infoWindowText?: string;

  @HostBinding('class')
  hostClass = 'block w-full';

  @HostBinding('class.h-80')
  @Input()
  defaultHeight = true;
  @Input()
  zoom = this.defaultZoom;
  @Input()
  latitude?: number;
  @Input()
  longitude?: number;
  @Input()
  streetViewControl = false;
  @Input()
  showMarkerInfo = false;
  @Input()
  markers: MapMarkerOption[] = [];
  @Input()
  circles: MapCircleOption[] = [];
  @Input()
  autoCompleteInput?: HTMLInputElement;
  @Input()
  fitMarkerBounds = false;

  @Output()
  cicleCenterChange = new EventEmitter<MapCircleOption>();
  @Output()
  circleRadiusChange = new EventEmitter<MapCircleOption>();
  @Output()
  autoCompleteChange = new EventEmitter<MapAutoCompleteChangeEvent>();

  readonly apiLoaded$: Observable<boolean>;

  mapInitialized = false;

  constructor(
    readonly mapsService: MapsService,
    private readonly ngZone: NgZone,
  ) {
    this.apiLoaded$ = mapsService.apiLoaded$;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['latitude'] || changes['longitude']) {
      this.setCenter({
        lat: this.latitude || defaultCenter.lat,
        lng: this.longitude || defaultCenter.lng,
      });
    }

    if (changes['streetViewControl']) {
      this.options.streetViewControl = this.streetViewControl;
    }

    // when fitMarkerBounds is set to true we force the map to fit the bounds of the markers
    if (changes['markerOptions']) {
      this.markersFitBounds();
    }
  }

  onMapInitialized() {
    this.mapInitialized = true;
    this.markersFitBounds();
    this.setupAutocomplete();
  }

  // used infernally and externally to set the center of the map
  public setCenter(center: LatLngLiteral, zoom?: number) {
    this.center = center;
    if (zoom) {
      this.zoom = zoom;
    }
  }

  onCenterChange(mapCircle: MapCircleComponent, circle: MapCircleOption) {
    const center = mapCircle.getCenter()?.toJSON();
    if (!center || !this.centerChanged(center, circle)) {
      return;
    }
    this.cicleCenterChange.emit({ ...circle, center });
  }

  private centerChanged(nextCenter: google.maps.LatLngLiteral, preCenter: MapCircleOption): boolean {
    return nextCenter.lat !== preCenter.center.lat || nextCenter.lng !== preCenter.center.lng;
  }

  onRadiusChange(mapCircle: MapCircleComponent, circle: MapCircleOption) {
    const radius = mapCircle.getRadius();
    if (!radius) {
      return;
    }
    const roundedRadius = Math.round(radius);
    if (roundedRadius === Math.round(circle.radius)) {
      return;
    }
    this.circleRadiusChange.emit({ ...circle, radius: roundedRadius });
  }

  openInfoWindow(marker: MapMarkerComponent, markerPosition: MapMarkerOption) {
    this.infoWindowText = markerPosition.info;
    if (!this.infoWindowText || !this.infoWindowComponent) {
      return;
    }
    this.infoWindowComponent.open(marker);
  }

  private setupAutocomplete() {
    if (!this.autoCompleteInput) {
      return;
    }

    const autocomplete = new google.maps.places.Autocomplete(this.autoCompleteInput);
    autocomplete.addListener('place_changed', () => {
      this.ngZone.run(() => {
        const place: google.maps.places.PlaceResult = autocomplete.getPlace();

        if (!place?.geometry?.location) {
          return;
        }

        const latitude = place.geometry.location.lat();
        const longitude = place.geometry.location.lng();

        if (place.geometry.viewport) {
          this.map?.fitBounds(place.geometry.viewport);
        } else {
          this.setCenter({ lat: latitude, lng: longitude }, 17);
        }

        this.autoCompleteChange.emit({
          name: place.name || '',
          latitude,
          longitude,
          formattedAddress: place.formatted_address || '',
        });
      });
    });
  }

  // set bounds to fit all markers
  private markersFitBounds() {
    if (!this.fitMarkerBounds || !this.mapInitialized) {
      return;
    }
    this.markers?.map((marker) => {
      const bounds = new google.maps.LatLngBounds();
      const latlng = new google.maps.LatLng(marker.lat, marker.lng);
      bounds.extend(latlng);
      this.map?.fitBounds(bounds);
    });
  }
}
