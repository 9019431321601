@if (apiLoaded$ | async) {
  <div class="relative size-full">
    <google-map
      (mapInitialized)="onMapInitialized()"
      [center]="center"
      [options]="options"
      [zoom]="zoom"
      height="100%"
      width="100%"
      class="size-full"
    >
      @for (marker of markers; track marker.id) {
        <map-marker
          #mapMarker="mapMarker"
          [position]="{ lat: marker.lat, lng: marker.lng }"
          [icon]="marker.icon || ''"
          [options]="markerOptions"
          (mapClick)="openInfoWindow(mapMarker, marker)"
        ></map-marker>
        @if (marker.radius) {
          <map-circle
            [radius]="marker.radius"
            [center]="{ lat: marker.lat, lng: marker.lng }"
            [options]="{
              fillColor: '#FF0000',
              strokeColor: '#FF0000'
            }"
          ></map-circle>
        }
      }

      @if (showMarkerInfo) {
        <map-info-window>{{ infoWindowText }}</map-info-window>
      }

      @for (circle of circles; track circle.id) {
        <map-circle
          #mapCircle="mapCircle"
          [center]="circle.center"
          [radius]="circle.radius"
          [options]="circleOptions"
          (centerChanged)="onCenterChange(mapCircle, circle)"
          (radiusChanged)="onRadiusChange(mapCircle, circle)"
        ></map-circle>
      }
    </google-map>

    <!-- slot to render content on top of the map -->
    <ng-content></ng-content>
  </div>
}
